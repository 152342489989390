(function($, undefined){

    $(document).ready(function() {

        $('.js-hMenuBtn').click(function(){
            $(this).toggleClass('active')
            $('.js-hMenu').toggleClass('active')
            $('header').toggleClass('active')
        })

        $('.js-goalSlider').slick({
            prevArrow: '<span class="slick-arrow arrow-prev"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2 9H16M2 9L9 2M2 9L9 16" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> </svg></span>',
            nextArrow: '<span class="slick-arrow arrow-next"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16 9H2M16 9L9 2M16 9L9 16" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> </svg></span>',
            infinite: false
        });
        $('.js-goalGallery').slick({
            prevArrow: '<span class="slick-arrow arrow-prev"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2 9H16M2 9L9 2M2 9L9 16" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> </svg></span>',
            nextArrow: '<span class="slick-arrow arrow-next"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16 9H2M16 9L9 2M16 9L9 16" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> </svg></span>',
            infinite: true
        });
        $('.js-comparSlider').slick({
            prevArrow: '<span class="slick-arrow arrow-prev"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2 9H16M2 9L9 2M2 9L9 16" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> </svg></span>',
            nextArrow: '<span class="slick-arrow arrow-next"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16 9H2M16 9L9 2M16 9L9 16" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> </svg></span>',
            infinite: true
        });
        $('.js-infoSlider').slick({
            prevArrow: '<span class="slick-arrow arrow-prev"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2 9H16M2 9L9 2M2 9L9 16" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> </svg></span>',
            nextArrow: '<span class="slick-arrow arrow-next"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16 9H2M16 9L9 2M16 9L9 16" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> </svg></span>',
            infinite: false
        });

        $('.js-compartab').click(function(e){
            e.preventDefault()
            $('.js-compartab').removeClass('active')
            $(this).addClass('active')
            $('.js-comparcont').addClass('hid')
            $('.js-comparcont'+$(this).attr('href')).removeClass('hid')
        })

        $('.js-servBtn').click(function(){
            var open = $(this).data('open');
            var hide = $(this).data('hide');
            $(this).toggleClass('active')
            $('.js-servCont'+$(this).data('id')).slideToggle()
            if ($(this).find('.js-servTxt').length > 0) {
                if ($(this).hasClass('active')) {
                    $(this).find('.js-servTxt').text(hide)
                } else {
                    $(this).find('.js-servTxt').text(open)
                }
            }
        })

        $(".fancybox").fancybox();

    });

})(jQuery);
